import React, { useEffect, useState,  } from 'react';
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate} from 'react-router-dom';
import styles from "./SMredirect.module.scss"

const RedirectHandler = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const location = useLocation();
  const navigate = useNavigate();
  const { platformname } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const twitterToken = searchParams.get("oauth_verifier");

  useEffect(() => {
    if (twitterToken) {
      fetch(`https://politix.tv/api/users/socials/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        },
        body: JSON.stringify({
          platform: "X",
          code: twitterToken,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then(() => {
          navigate(`/profile/me/settings`);
        })
        .catch((error) => {
          navigate(`/profile/me/settings`);
          console.log(error);
        });
    }

    if (code) {
      fetch(`https://politix.tv/api/users/me/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        },
        body: JSON.stringify({
          platform: platformname,
          code: code,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then(() => {
          navigate(`/profile/me/settings`);
        })
        .catch((error) => {
          navigate(`/profile/me/settings`);
          console.log(error);
        });
    }
  }, [code, location.search, navigate, platformname, twitterToken, user.access]);

  return (
    <div className={styles.container}>
      <p>Redirecting...</p>
      <p>If you are not redirected, please contact support.</p>
    </div>
  );
}

export default RedirectHandler;
