import React, {useState, useEffect, useContext} from "react";
import styles from "./Sidebar.module.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExploreIcon from "@mui/icons-material/Explore";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import VideocamIcon from "@mui/icons-material/Videocam";
import AuthService from "../../services/auth.service";
import { AppContext } from "../../store/store";
import Auth from "../auth/Auth/Auth";
import { ACTIONS } from "../../store/reducer";
import ReactGA from "react-ga";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import LivestreamService from "../../sevices2/LivestreamServices";
import UsersService from "../../sevices2/usersServices";
import { DarkModeContext } from "../../utils/darkMode";


const Sidebar = () => {
  const { dispatch } = useContext(AppContext);

  const [user, setUser] =useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { userProfile } =useContext(AppContext);
  const [streamPerms, setStreamPerms] =useState(false);
  const [channelID, setChannelID] =useState(null);
  const [liveChannelsList, setLiveChannelsList] =useState([]);
  const location = useLocation();

  const canStream = (access) => {
    UsersService.getStreamerStatus(access)
      .then((response) => {
        setStreamPerms(response.message);
      })
      .catch((error) => {
        console.log(error.config);
      });
  };

  const getUserData = (access) => {
    UsersService.getUserData(access)
    .then((response) => {
        setChannelID(response.channel_id);    
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLiveChannels = (access) => {
    fetch(`https://politix.tv/api/livestream/content/live `, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setLiveChannelsList(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  React.useEffect(() => {
    getLiveChannels();
  }, []);

 useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [user?.access]);

 useEffect(() => {
    if (user?.access) {
      getUserData(user.access);
    }
  }, [user?.access]);

  useEffect(() => {
    if (user?.access) {
      canStream(user.access);
    }
  }, [user?.access]);


  const history = useNavigate();
  const handleSidebarItemClick = (route) => {
    history(route);
  };

  const onLogout = () => {
    dispatch({ type: ACTIONS.SET_USER, payload: {} });
    ReactGA.event({
      category: "User",
      action: "Logged Out",
    });
    AuthService.logout();
    if (
      location["pathname"].includes("profile") ||
      location["pathname"].includes("streamer")
    ) {
      history("/");
    }
  };

 useEffect(() => {
    const fetchUser = async () => {
      const newUser = await AuthService.getCurrentUser();
      setUser(newUser);
    };

    fetchUser();
  }, [userProfile]); // Empty dependency array to run the effect on every render
  
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

  return (
    <>
      <div className={styles.containerPadding}>
        <div
          className={styles.sidebar}
          style={{ backgroundColor: darkMode ? "#151521" : "" }}
        >
          {(!userProfile || !userProfile["access"]) && (
            <div className={styles.login}>
              <Auth disableJoinBtn="true" />
            </div>
          )}
          {user && userProfile["access"] && (
            <>
              {streamPerms && channelID && (
                <div
                  className={styles.sidebarTitle}
                  onClick={() =>
                    handleSidebarItemClick(`/channel/${channelID}`)
                  }
                  style={{
                    padding: "10px 0px 5px 5px",
                    color: darkMode ? "#FFFFFF" : "",
                  }}
                >
                  <AccountBoxIcon
                    style={{
                      color: darkMode ? "#FFFFFF" : "#012060",
                      marginRight: "5px",
                      paddingTop: "5px",
                    }}
                  />
                  {user["username"]}
                </div>
              )}
              {!channelID && (
                <div
                  className={styles.sidebarTitle}
                  onClick={() =>
                    handleSidebarItemClick(`/profile/user/${user["username"]}`)
                  }
                  style={{
                    padding: "10px 0px 5px 5px",
                    color: darkMode ? "#FFFFFF" : "",
                  }}
                >
                  <AccountBoxIcon
                    style={{
                      color: darkMode ? "#FFFFFF" : "#012060",
                      marginRight: "5px",
                      paddingTop: "5px",
                    }}
                  />
                  {user["username"]}
                </div>
              )}
              {channelID && (
                <div
                  className={styles.sidebarItem}
                  onClick={() =>
                    handleSidebarItemClick(`/streamer/${channelID}/`)
                  }
                  style={{ color: darkMode ? "#8e8d98" : "" }}
                >
                  Dashboard
                </div>
              )}
              {streamPerms && !channelID && (
                <div
                  className={styles.sidebarItem}
                  style={{ color: darkMode ? "#8e8d98" : "" }}
                  onClick={() =>
                    handleSidebarItemClick("/live/channel-onboard?step=welcome")
                  }
                >
                  Create Channel
                </div>
              )}
              <div
                className={styles.sidebarItem}
                style={{ color: darkMode ? "#8e8d98" : "" }}
                onClick={() => handleSidebarItemClick("following")}
              >
                Following
              </div>

              <div
                className={styles.sidebarItem}
                style={{ color: darkMode ? "#8e8d98" : "" }}
                onClick={() => handleSidebarItemClick("subscriptions")}
              >
                Subscriptions
              </div>

              {/* <div
                className={styles.sidebarItem}
                onClick={() => handleSidebarItemClick("Friends")}
                style={{ paddingBottom: "10px" }}
              >
                Friends
              </div> */}
            </>
          )}

          <div
            className={styles.sidebarTitle}
            onClick={() => handleSidebarItemClick("/search?q=politics")}
            style={{
              paddingTop: "16px",
              paddingBottom: "16px",
              borderBottom: "1px solid white",
              borderTop: "1px solid white",
              color: darkMode ? "#FFFFFF" : "",
            }}
          >
            <ExploreIcon
              style={{
                color: darkMode ? "#FFFFFF" : "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Explore
          </div>
          <div
            className={styles.liveNow}
            onClick={() => handleSidebarItemClick("/")}
            style={{
              paddingTop: "16px",
              paddingBottom: "10px",
              color: darkMode ? "#FFFFFF" : "",
            }}
          >
            <VideocamIcon
              style={{
                color: darkMode ? "#FFFFFF" : "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Live Now
          </div>
          {liveChannelsList.length > 0 && (
            <div className={styles.liveNowStreamersList}>
              {liveChannelsList.map((streamer, index) => (
                <div
                  className={styles.liveNowStreamer}
                  style={{ backgroundColor: darkMode ? "#8e8d98" : "" }}
                  onClick={() =>
                    handleSidebarItemClick(`/channel/${streamer.id}`)
                  }
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/channel/${streamer.id}}`}
                  >
                    <img
                      src={streamer.avatar}
                      className={styles.channelLogo}
                      alt="logo"
                    />
                  </Link>
                  {streamer.name}
                </div>
              ))}
            </div>
          )}

          <div
            className={styles.sidebarTitle}
            onClick={() => handleSidebarItemClick("/about-us")}
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderTop: "1px solid white",
              color: darkMode ? "#FFFFFF" : "",
            }}
          >
            <InfoIcon
              style={{
                color: darkMode ? "#FFFFFF" : "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            About Us
          </div>
          {user && userProfile["access"] && (
            <div
              className={styles.sidebarTitle}
              onClick={() => handleSidebarItemClick("/profile/me/settings")}
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                color: darkMode ? "#FFFFFF" : "",
              }}
            >
              <SettingsIcon
                style={{
                  color: darkMode ? "#FFFFFF" : "#012060",
                  marginRight: "5px",
                  paddingTop: "5px",
                }}
              />
              Settings
            </div>
          )}
          {user && userProfile["access"] && (
            <div
              className={styles.sidebarTitle}
              onClick={onLogout}
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                color: darkMode ? "#FFFFFF" : "",
              }}
            >
              <LogoutIcon
                style={{
                  color: darkMode ? "#FFFFFF" : "#012060",
                  marginRight: "9px",
                  marginLeft: "3px",
                  fontSize: "large",
                }}
              />
              Log Out
            </div>
          )}
          <div className={styles.darkModeWrapper}>
            <p style={{color: darkMode ? 'white' : ''}}>{darkMode ? "Light Mode" : "Dark Mode"}</p>
            <button
              className={`${styles.toggleButton} ${
                darkMode ? styles.toggled : ""
              }`}
              onClick={toggleDarkMode}
            >
              <div className={styles.thumb}></div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
